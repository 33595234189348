import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import InterestedBuyers from '../components/InterestedBuyers';
import {
  Row,
  Row8Col,
  Row10Col,
  BackpageBannerWrapper,
  BackpageBanner,
  PageTitle,
  MainPageContent,
} from '../components/CustomComponents';

export default class BackpageLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    const { location } = this.props;

    return (
      <Layout
        location={location}
        pageMetaTitle={markdownRemark.frontmatter.title}
        pageMetaDescription={markdownRemark.frontmatter.meta_description}
      >
        <BackpageBannerWrapper>
          <BackpageBanner
            fluid={markdownRemark.frontmatter.hero_image.childImageSharp.fluid}
          ></BackpageBanner>
        </BackpageBannerWrapper>
        <Row>
          <Row10Col>
            <MainPageContent>
              <Row8Col>
                <PageTitle>{markdownRemark.frontmatter.title}</PageTitle>
                <div
                  // you need this inner-content class for the margin on the last child to get taken off
                  className="inner-content"
                  dangerouslySetInnerHTML={{
                    __html: markdownRemark.html,
                  }}
                />
              </Row8Col>
            </MainPageContent>
          </Row10Col>
        </Row>
        <InterestedBuyers />
      </Layout>
    );
  }
}

export const BackPageQuery = graphql`
  query pageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        hero_image {
          childImageSharp {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        meta_description
      }
    }
  }
`;
